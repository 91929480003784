import React from "react"
import Layout from "../../../components/sermonLayoutElMirage"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout sermonSrc="https://www.youtube.com/embed/kofuUQyNvdQ">
    <SEO title="A Cost, a Crowd, and a Criminal - The Week That Changed the World" />
  </Layout>
)

export default SermonPost
